/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import './src/styles/styles.scss';

//if (ga) {
//  ga('set', 'anonymizeIp', 1);
//}

const ReactDOM = require('react-dom');

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback);
  };
}
